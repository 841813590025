import React from "react";
import "./stars.scss";
import Typed from "react-typed";
import piddletrenthide from '../img/piddletrenthide.jpg'

class Intro extends React.Component {
  render() {
    return (
      <div id="home" className="intro route bg-image background">
        <div className="intro-content display-table">
          <div className="table-cell">
            <div className="container">
              <h1 className="intro-title mb-4">Elderberry Construction Ltd.</h1>
              <p className="intro-subtitle">
                <span className="text-slider-items"></span>
              </p>
              <img src={piddletrenthide} alt="" className="img-fluid" />
              <p className="pt-3">
                <a
                  className="btn btn-primary btn js-scroll px-4"
                  href="#about"
                  role="button"
                >
                  About us
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
