import React from "react";

//import stock
import bungalow from "../img/bungalow.jpg";
import fireplace from "../img/fireplace.jpg";
import kitchen from "../img/kitchen.jpg";
import twohouses from "../img/twohouses.jpg";
import kitchen2 from "../img/kitchen2.jpg"
import tolpuddle from "../img/tolpuddle.jpg"
import mainhouse from '../img/mainhouse.jpg'
import cottage from '../img/cottage.jpg'



class Portfolio extends React.Component {
  render() {
    return (
      <section id="work" className="portfolio-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Gallery</h3>
                <p className="subtitle-a">
                  Please browse some photos of previous projects
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="work-box">
                <a href={twohouses} data-lightbox="gallery-vmarine">
                  <div className="work-img">
                    <img src={twohouses} alt="" className="img-fluid" />
                  </div>
                 
                </a>
               
              </div>
            </div>
            <div className="col-md-6">
              <div className="work-box">
                <a href={bungalow} data-lightbox="gallery-aguadeluz">
                  <div className="work-img">
                    <img src={bungalow} alt="" className="img-fluid" />
                  </div>
                  
                </a>
              
              </div>
            </div>
            <div className="col-md-6">
              <div className="work-box">
                <a href={fireplace} data-lightbox="gallery-todo">
                  <div className="work-img">
                    <img src={fireplace} alt="" className="img-fluid" />
                  </div>
          
                </a>
      
              </div>
            </div>
            <div className="col-md-6">
              <div className="work-box">
                <a href={kitchen} data-lightbox="gallery-medlingos">
                  <div className="work-img">
                    <img src={kitchen} alt="" className="img-fluid" />
                  </div>
                  
                </a>
               
              </div>
            </div>

            <div className="col-md-6">
              <div className="work-box">
                <a href={tolpuddle} data-lightbox="gallery-medlingos">
                  <div className="work-img">
                    <img src={tolpuddle} alt="" className="img-fluid" />
                  </div>
                  
                </a>
               
              </div>
            </div>

            <div className="col-md-6">
              <div className="work-box">
                <a href={kitchen2} data-lightbox="gallery-medlingos">
                  <div className="work-img">
                    <img src={kitchen2} alt="" className="img-fluid" />
                  </div>
                  
                </a>
               
              </div>
            </div>


            <div className="col-md-6">
              <div className="work-box">
                <a href={mainhouse} data-lightbox="gallery-medlingos">
                  <div className="work-img">
                    <img src={mainhouse} alt="" className="img-fluid" />
                  </div>
                  
                </a>
               
              </div>
            </div>


            <div className="col-md-6">
              <div className="work-box">
                <a href={cottage} data-lightbox="gallery-medlingos">
                  <div className="work-img">
                    <img src={cottage} alt="" className="img-fluid" />
                  </div>
                  
                </a>
               
              </div>
            </div>
        
        
        
        
        
        
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
