import React from "react";
import backlane from '../img/backlane.jpg'

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      skills: [
        { id: "HTML5_skill", content: "HTML5", porcentage: "80%", value: "80" },
        { id: "CSS3_skill", content: "CSS3", porcentage: "75%", value: "75" },
        {
          id: "JavaScript_skill",
          content: "JavaScript",
          porcentage: "90%",
          value: "90"
        },
        { id: "PHP_skill", content: "PHP", porcentage: "70%", value: "70" },
        {
          id: "ReactJS_skill",
          content: "ReactJS",
          porcentage: "80%",
          value: "80"
        },
        {
          id: "Python_skill",
          content: "Python",
          porcentage: "75%",
          value: "75"
        },
        {
          id: "VanillaJS_skill",
          content: "VanillaJS",
          porcentage: "85%",
          value: "85"
        },
        {
          id: "Wordpress_skill",
          content: "Wordpress",
          porcentage: "80%",
          value: "80"
        }
      ],
      about_me_left: [
        {
          id: "first-p-about",
          content:
            "Elderberry Construction Ltd was established by Nick and Patsy Grace in 1997. Based in Dorset, we have established ourselves as a small quality developer/builder specialising mainly in small period style rural developments. Preferring to take projects from conception to completion we hunt out sites, involve ourselves in the details of the project design, guide the scheme through planning and finally oversee the development providing hands on site management."
        },
        {
          id: "second-p-about",
          content:
            "We are duly proud of the homes we have created. Please take a moment to browse this web site to see some examples of our work."
        },

      ],
      about_me_right: [
        // {
        //   id: "first-p-about",
        //   content:
        //     "Lorem ipsum pariatur consectetur laboris occaecat nulla aliqua irure ad deserunt duis. Eiusmod nulla cupidatat labore sint sit aute dolore irure nostrud ut incididunt. Anim laborum reprehenderit labore magna ut dolore quis irure. Labore ea duis deserunt ullamco irure fugiat deserunt ut nisi ea minim proident. Nisi consectetur do non magna duis aliqua minim minim veniam. In occaecat minim qui consequat elit mollit consectetur non id tempor. Amet adipisicing occaecat tempor culpa quis est duis."
        // },



      ]
    };
  }

  render() {
    return (
      <section id="about" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="title-box-2">
                        <h5 className="title-left px-2 px-md-0">About Us</h5>
                        <br className=""></br>
                        <div className="about-me pt-4 pt-md-0 px-2 px-md-0">

                          {this.state.about_me_left.map(content => {
                            return (
                              <p className="paragraph-text" key={content.id}>
                                {content.content}
                              </p>
                            );
                          })}

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="about-me pt-0">

                      {this.state.about_me_right.map(content => {
                        return (
                          <p className="lead" key={content.id}>
                            {content.content}
                          </p>
                        );
                      })}
                      <img src={backlane} alt="" className="img-fluid" />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
