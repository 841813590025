import React from "react";
import dwelling1 from '../img/Dwelling-1.jpg';
import dwelling2 from '../img/Dwelling-2.jpg';



class CurrentProjects extends React.Component {
  constructor() {
    super();
    this.state = {
      about_me_left: [
        {
          id: "first-p-about",
          content:
            "We are currently creating two stunning “Arts & Craft” style homes on the edge of the village of Charminster, near Dorchester, Dorset. Both houses will have spacious living accommodation downstairs with four bedrooms, two en suites and a family bathroom on the first floor. Finished to a high standard with attention to detail both inside and out these houses promise to provide both comfortable and stylish living."
        },


      ],
      about_me_right: [
        // {
        //   id: "first-p-about",
        //   content:
        //     "Lorem ipsum pariatur consectetur laboris occaecat nulla aliqua irure ad deserunt duis. Eiusmod nulla cupidatat labore sint sit aute dolore irure nostrud ut incididunt. Anim laborum reprehenderit labore magna ut dolore quis irure. Labore ea duis deserunt ullamco irure fugiat deserunt ut nisi ea minim proident. Nisi consectetur do non magna duis aliqua minim minim veniam. In occaecat minim qui consequat elit mollit consectetur non id tempor. Amet adipisicing occaecat tempor culpa quis est duis."
        // },
      ]
    };
  }

  render() {
    return (
      <section id="current-projects" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="title-box-2">
                        <h5 className="title-left px-2 px-md-0">Current Projects</h5>
                        <br></br>
                        <div className="about-me pt-4 pt-md-0 px-2 px-md-0">

                          {this.state.about_me_left.map(content => {
                            return (
                              <p className="paragraph-text" key={content.id}>
                                {content.content}
                              </p>
                            );
                          })}

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="about-me pt-4 pt-md-0">
                      <img src={dwelling1} alt="" className="img-fluid" />
                      <p>Foxmoor House</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="about-me pt-4 pt-md-0">
                      <img src={dwelling2} alt="" className="img-fluid" />
                      <p>Garden Lodge</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="about-me pt-4 pt-md-0">
                      <div className="paragraph-text font-weight-bold">
                        Foxmoor House has been bought off-plan and is no longer available.
                      </div>

                      <div className="paragraph-text mt-4">
                        For more information regarding our second property, Garden Lodge, please contact either ourselves via our <a href="#contact">contact</a> section or the Selling Agents DOMVS direct on <a href="tel:01305757300">01305-757300</a>.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CurrentProjects;
